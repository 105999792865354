export default {
    data() {
        return {
            show: false,
            productEquityDescribeDetail: [],
        }
    },
    props: {
        productInfo: {
            type: Object,
            default: ()=>{},
        },
        personNum: {
            type: Number,
            default: 0,

        }
    },
    components: {},
    created() {
    },
    mounted() {
    },
    activated() {
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {},
    methods: {
        showDetail() {
            this.productEquityDescribeDetail = JSON.parse(this.productInfo.productEquityDescribeDetail);
            this.show = true;
        }
    }
}
